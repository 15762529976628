import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import HomePage from "@screens/Home/index";
import Forbidden from './stories/screens/Forbidden/index'

import "tabler-react/dist/Tabler.css";

import { retrieveSettings, canAccess } from "./services/storage";

import Usuario from "./modules/usuario/container";
import Colaborador from "./modules/colaborador/container";
import Lancamentos from "./modules/lancamento/container";
import Remessas from "./modules/remessa/container";
import Cargo from "./modules/cargo/container";
import Competencia from "./modules/competencia/container"
import Exportacao from "./modules/exportacao/container"
import Login from "./modules/login/container";
import Senha from "./modules/senha/container";
import Importacao from "./modules/importacao/container";
import Relatorio from "./modules/relatorio/container";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (!retrieveSettings().isAuthenticated) ? ( // fazendo a condição se tiver token ele passar 
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      ) : (canAccess(rest.path) ? (<Component {...props} />)
        : (<Redirect to={{ pathname: "/forbidden", state: { from: props.location } }} />))
    }
  />
);

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/senha" component={Senha} />

          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute path="/usuario" component={Usuario} />
          <PrivateRoute path="/cargo" component={Cargo} />
          <PrivateRoute path="/competencias" component={Competencia} />
          <PrivateRoute path="/colaborador" component={Colaborador} />
          <PrivateRoute path="/lancamentos" component={Lancamentos} />
          <PrivateRoute path="/remessas" component={Remessas} />
          <PrivateRoute path="/exportacao" component={Exportacao} />
          <PrivateRoute path="/importacao" component={Importacao} />
          <PrivateRoute path="/relatorio" component={Relatorio} />

          <Route exact path="/forbidden" component={Forbidden} />
          <Redirect path='*' to='/' />
        </Switch>
      </Router>
    )
  }
}

export default App;