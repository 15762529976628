import React from "react"
import { observer, inject } from "mobx-react"

import BaseContainer from "../../container/BaseContainer"
import { customToast, formatCurrency, showSwal } from "../../store/ViewStore/Utils"
import usuarioService from "../usuario/service"
import origemService from "../origem/service"
import competenciaService from "../competencia/service"
import remessaService from '../remessa/service'
import lancamentoService from "./service"
import lotacaoService from '../lotacao/service'

import Lancamento from "./index"
import Form from "./form"
import Grid from "./grid"

@inject("stateStore", "lancamentoGridStore", "lancamentoFormStore")
@observer
class Container extends BaseContainer {

    constructor(props) {
        super(props)

        this.gridStore = this.props.lancamentoGridStore
        this.formStore = this.props.lancamentoFormStore
        this.stateStore = this.props.stateStore

        usuarioService._token = this._stateStore.apiToken
        origemService._token = this._stateStore.apiToken
        competenciaService._token = this._stateStore.apiToken
        remessaService._token = this._stateStore.apiToken
        lotacaoService._token = this._stateStore.apiToken

        this.origens()
        this.fetchRows()
    }

    async origens() {
        try {
            this._formStore.changeState("pending")
            const origens = await this.getOrigens()
            this._gridStore.setOrigensSelect(origens)
            this._formStore.changeState("done")
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    async showForm(params) {
        try {
            this._formStore.changeState("pending")
            this._formStore.setProcessado(false)
            this._formStore.setDisableBtnSalvar(false)

            const origens = await this.getOrigens()
            this._formStore.setOrigemSelect(origens)

            if (params) {
                const { competencia, origem_id, remessa_id, retificado, calculado } = params

                // pegando os lancamentos
                await this._formStore.fetch(
                    competencia,
                    origem_id,
                    retificado,
                    calculado ? remessa_id : null,
                    calculado ? false : true
                )

                const origem = origens.find(origem => origem.value === origem_id)
                this._formStore.onChangeOrigem(origem)

                // lancamentos calculados
                if (calculado) {
                    this._formStore.setProcessado(true)
                    this._formStore.setDisableBtnSalvar(true)
                } else if (retificado) {
                    const lancamentosRemessaAnterior = await this.getLancamentosRemessaAnterior(origem_id, competencia)
                    this._formStore.lancamentos.forEach(lan => {
                        const lanAnterior = lancamentosRemessaAnterior.find(l => l.lotacao_id === lan.lotacao_id)
                        if (!lanAnterior) {
                            lan.setSituacao({
                                updated: false,
                                badgeColor: 'success',
                                badgeText: 'Novo lançamento',
                                snapshot_atual: null,
                                snapshot_anterior: null
                            })
                        } else {
                            if (this.compararSnapshot(
                                lanAnterior.lotacao_snapshot,
                                lanAnterior.lotacao_atual_snapshot
                            )) {

                                let situacao = null
                                const categoriaAtual = { ...lanAnterior.lotacao.categoria }
                                const categoriaAnterior = { ...lanAnterior.categoria }

                                if (this.compararSnapshot(
                                    lan.lotacao_snapshot,
                                    lanAnterior.lotacao_snapshot
                                )) {
                                    situacao = {
                                        badgeColor: 'warning',
                                        badgeText: 'Lotação atualizada'
                                    }
                                    lan.setCategoria(categoriaAtual)
                                } else {
                                    situacao = {
                                        badgeColor: 'info',
                                        badgeText: 'Remessa anterior'
                                    }
                                    lan.setCategoria(categoriaAnterior)
                                }

                                lan.setSituacao({
                                    ...situacao,
                                    updated: true,
                                    snapshot_anterior: lanAnterior.lotacao_snapshot,
                                    snapshot_atual: lanAnterior.lotacao_atual_snapshot,
                                    categoria_atual: categoriaAtual,
                                    categoria_anterior: categoriaAnterior
                                })
                            }
                        }
                    })
                }
            } else {
                this._formStore.clear()
            }

            this.onSwitchComponent()
            this._formStore.changeState("done")
        } catch (error) {
            this._formStore.changeState("error")
            this._formStore.clearLancamentos()
            customToast["error"](error.message)
        }
    }

    async revertRemessa(remessaId) {
        try {
            const resp = await showSwal({
                title: `Deseja reabrir os lançamentos? Todas as informações de cálculo da GIP serão apagadas.`,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                focusCancelButton: true,
                reverseButtons: true
            })

            if (resp.value) {
                this._formStore.changeState("pending")
                const resp = await remessaService.reabrir(remessaId)
                // Recarregando a lista de lancamentos
                await this._gridStore.fetchRows()
                this._formStore.changeState("done")
                customToast["info"](resp.message)
            }
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    async calculateRemessa(remessaId) {
        try {
            const lbl = 'calcular' //recalculo ? 'recalcular' : 'calcular'
            const resp = await showSwal({
                title: `Deseja ${lbl} os lançamentos?`,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar'
            })

            if (resp.value) {
                this._formStore.changeState("pending")
                const resp = await remessaService.calcular(remessaId)
                // Recarregando a lista de lancamentos
                await this._gridStore.fetchRows()
                this._formStore.changeState("done")
                customToast["info"](resp.message)
            }
        } catch (error) {
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    showReport(reportName, origemId, competencia) {
        const baseUrlRelatorios = this._stateStore.apiReportUrl
        const params = `/${reportName}&ORIGEM_ID=${origemId}&COMPETENCIA=${competencia}&RETIFICACAO=false`

        window.open(baseUrlRelatorios + params)
    }

    mountOrigemSelect(origens, selectOrigens = []) {
        origens.forEach(origem => {
            if (origem['all_children_origens'].length > 0) {
                return this.mountOrigemSelect(origem['all_children_origens'], selectOrigens)
            }
            selectOrigens.push({ value: origem.id, label: origem.nome })
        })

        return selectOrigens
    }

    async onChangeOrigem(origem) {
        this._formStore.clearLancamentos()
        this._formStore.onChangeOrigem(origem)
        if (!origem) return

        try {
            this._formStore.changeState("pending")

            const competencia = this._formStore.competencia
            const retificado = this._formStore.retificado
            const { value: origem_id } = this._formStore.origem
            let listaProducao = []
            let lancamentos = []

            await competenciaService.check(competencia, origem_id)

            if (retificado) {

                const grupoLancamento = await this.getLancamentosRemessaAnterior(origem_id, competencia)

                if (grupoLancamento.length === 0)
                    throw new Error('Só será possível criar lançamentos retificados se já houver um grupo de lançamentos calculados (sem retificação).')

                // lancamentos remessa anterior
                lancamentos = grupoLancamento.map(lan => {

                    let situacao_lotacao = undefined
                    let categoria = { ...lan.lotacao.categoria }

                    // verificando se a lotacao foi atualizada
                    if (this.compararSnapshot(lan.lotacao_atual_snapshot, lan.lotacao_snapshot)) {
                        situacao_lotacao = {
                            updated: true,
                            badgeColor: 'info',
                            badgeText: 'Remessa anterior',
                            snapshot_atual: lan.lotacao_atual_snapshot,
                            snapshot_anterior: lan.lotacao_snapshot
                        }

                        // adicionando a categoria atual
                        situacao_lotacao.categoria_atual = categoria

                        // adicionando categoria anterior
                        categoria = { ...lan.categoria }
                        situacao_lotacao.categoria_anterior = categoria
                    }

                    const lancamento = {
                        lotacao_id: lan.lotacao_id,
                        categoria_id: lan.categoria_id,
                        valor_vb: lan.valor_vb,
                        valor_cargo: lan.valor_cargo,
                        colaborador: { ...lan.lotacao.colaborador },
                        categoria: categoria,
                        cargo: { ...lan.lotacao.cargo },
                        percentual_desconto: lan.percentual_desconto,
                        valor_producao: lan.valor_producao,
                        matricula: lan.lotacao.matricula,
                        lotacao_snapshot: lan.lotacao_snapshot,
                        situacao_lotacao: situacao_lotacao
                    }

                    // inicializa os campos dos acs
                    if (lan.categoria.exibe_campos_agente_comunitario_saude) {
                        lancamento.qtde_faltas_n_justificadas = lan.qtde_faltas_n_justificadas || 0
                        lancamento.qtde_cadastros_realizados = lan.qtde_cadastros_realizados || 0
                        lancamento.qtde_visitas = lan.qtde_visitas || 0
                        lancamento.qtde_visitas_cresca = lan.qtde_visitas_cresca || 0
                        lancamento.qtde_grupos = lan.qtde_grupos || 0
                        lancamento.qtde_folgas = lan.qtde_folgas || 0
                        lancamento.afastamento = lan.afastamento
                        lancamento.valor_producao_media = lan.valor_producao_media
                    }

                    return lancamento
                })
            } else {

                // Carregando as lotacoes
                const { data: { rows: lotacoes } } = await this.onLoadLotacoes(
                    origem.value,
                    competencia,
                    retificado
                )

                const { data: { rows: producao } } = await this.getProducao(origem.value, this._formStore.competencia)
                listaProducao = producao

                lancamentos = lotacoes.map(lotacao => {

                    // Obtendo a producao do colaborador
                    let valorProducao = undefined
                    if (lotacao.categoria.exibe_campo_produtividade) {
                        const { colaborador: { cns } } = lotacao
                        const producao = listaProducao.find(p => {
                            return p.cns === cns
                        })

                        if (producao) valorProducao = producao.valor_produzido
                    }

                    const [lancamento] = lotacao.lancamentos

                    // Lanca uma exception caso os lancamentos ja estejam calculados
                    if (lancamento && lancamento.remessa_id) {
                        throw new Error('Já existem lançamentos calculados para competência selecionada.')
                    }

                    const lan = {
                        id: lancamento ? lancamento.id : null,
                        lotacao_id: lotacao.id,
                        categoria_id: lotacao.categoria_id,
                        valor_vb: lotacao.vencimento_base ? lotacao.vencimento_base : null,
                        valor_cargo: lotacao.cargo ? lotacao.cargo.remuneracao : null,
                        colaborador: { ...lotacao.colaborador },
                        categoria: { ...lotacao.categoria },
                        cargo: { ...lotacao.cargo },
                        percentual_desconto: lancamento ? lancamento.percentual_desconto : null,
                        valor_producao: lancamento ? lancamento.valor_producao : valorProducao,
                        matricula: lotacao.matricula,
                        lotacao_snapshot: lotacao.snapshot
                    }

                    // inicializa os campos dos acs
                    if (lotacao.categoria.exibe_campos_agente_comunitario_saude) {
                        lan.afastamento = lancamento ? lancamento.afastamento : ''
                        lan.valor_producao_media = lancamento ? lancamento.valor_producao_media : ''
                        lan.qtde_faltas_n_justificadas = lancamento ? lancamento.qtde_faltas_n_justificadas : 0
                        lan.qtde_cadastros_realizados = lancamento ? lancamento.qtde_cadastros_realizados : 0
                        lan.qtde_visitas = lancamento ? lancamento.qtde_visitas : 0
                        lan.qtde_visitas_cresca = lancamento ? lancamento.qtde_visitas_cresca : 0
                        lan.qtde_grupos = lancamento ? lancamento.qtde_grupos : 0
                        lan.qtde_folgas = lancamento ? lancamento.qtde_folgas : 0
                    }

                    return lan
                })
            }

            this._formStore.addLancamentos(lancamentos)
            this._formStore.changeState("done")
        } catch (error) {
            this._formStore.onChangeOrigem()
            this._formStore.changeState("error")
            customToast["error"](error.message)
        }
    }

    async getLancamentosRemessaAnterior(origem_id, competencia) {

        const { label: origemLabel } = this._formStore.origem

        // pega o ID da ultima remessa calculada
        const { data: { rows } } = await remessaService.fetch({
            competencia,
            origem_id,
            ultimaCalculada: true
        })

        if (rows.length === 0)
            return []

        const [{ id: remessa_id }] = rows
        this._formStore.setRemessaAnteriorId(remessa_id)
        const { data: { rows: lancamentos } } = await lancamentoService.getOne({ remessa_id })
        return lancamentos[competencia][origemLabel]
    }

    onSave() {
        if (!this._formStore.lancamentos.length) {
            customToast["error"]("Não há lançamentos para a origem selecionada.")
            return
        }
        super.onSave()
    }

    async getOrigens() {
        let origens = await usuarioService.getOrigens()
        origens = this.mountOrigemSelect(origens)
        // Removendo origens duplicados
        origens = Array.from(new Set(origens.map(origem => origem.value)))
            .map(value => {
                return origens.find(origem => origem.value === value)
            })
        return origens
    }

    async onLoadLotacoes(origemId, competencia, retificado) {
        return await origemService.getLotacoes(origemId, competencia, retificado)
    }

    async getProducao(origemId, competencia) {
        return await origemService.getProducao(origemId, competencia)
    }

    async removerLancamento(lotacaoID, nomeColaborador) {
        const resp = await showSwal({
            title: 'Atenção',
            html: `Deseja remover o lotação de <strong>${nomeColaborador}</strong> dos lançamentos?`,
            type: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            focusCancel: true
        })

        if (resp.value) {
            this._formStore.removerLancamento(lotacaoID)
        }
    }

    async visualizarLotacao(lotacaoID, atual, anterior) {
        const lotacaoAtual = JSON.parse(atual)
        const lotacaoAnterior = JSON.parse(anterior)

        const html = `<div class="container mt-4 mb-4">
                <div class="row divider">
                    <div class="col-6">
                        <h3>Remessa anterior</h3>
                        <p class="mb-2 text-left">
                            <strong>Categoria:</strong> ${lotacaoAnterior.categoria_descricao}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>CH:</strong> ${lotacaoAnterior.ch ?? '-'}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>CH Sup.:</strong> ${lotacaoAnterior.ch_suplementada ?? '-'}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>Venc. base:</strong> ${lotacaoAnterior.vencimento_base ? formatCurrency(lotacaoAnterior.vencimento_base, 'pt-BR', 'BRL') : '-'}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>Cargo:</strong> ${lotacaoAnterior.cargo_simbolo ?? '-'}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>Cargo valor:</strong> ${lotacaoAnterior.cargo_remuneracao ? formatCurrency(lotacaoAnterior.cargo_remuneracao, 'pt-BR', 'BRL') : '-'}
                        </p>
                    </div>
                    <div class="col-6">
                        <h3>Atual</h3>
                        <p class="mb-2 text-left">
                            <strong>Categoria:</strong> ${lotacaoAtual.categoria_descricao}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>CH:</strong> ${lotacaoAtual.ch ?? '-'}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>CH Sup.:</strong> ${lotacaoAtual.ch_suplementada ?? '-'}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>Venc. base:</strong> ${lotacaoAtual.vencimento_base ? formatCurrency(lotacaoAtual.vencimento_base, 'pt-BR', 'BRL') : '-'}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>Cargo:</strong> ${lotacaoAtual.cargo_simbolo ?? '-'}
                        </p>
                        <p class="mb-2 text-left">
                            <strong>Cargo valor:</strong> ${lotacaoAtual.cargo_remuneracao ? formatCurrency(lotacaoAtual.cargo_remuneracao, 'pt-BR', 'BRL') : '-'}
                        </p>
                    </div>
                </div>
            </div>`

        const resp = await showSwal({
            title: 'Lotações',
            html: html,
            width: 600,
            confirmButtonText: 'Usar lotação anterior',
            showCancelButton: true,
            cancelButtonText: 'Usar lotação atual',
            customClass: {
                confirmButton: 'bg-info',
                cancelButton: 'bg-warning'
            },
            allowEscapeKey: false,
            allowOutsideClick: false
        })

        this._formStore.changeLotacaoSnapshotLancamento(lotacaoID, resp.value)
    }

    async addLotacao() {
        if (this._formStore.matriculaSearch) {
            try {

                if (this._formStore.lancamentos.find(l => l.matricula === this._formStore.matriculaSearch)) {
                    customToast['info']('Já existe um lançamento com essa matrícula')
                    return
                }

                this._formStore.changeState('pending')

                const { data: { rows } } = await lotacaoService.fetch({
                    matricula: this._formStore.matriculaSearch,
                    origem_id: this._formStore.origem.value
                })

                if (rows.length === 0) {
                    customToast['info']('Nenhuma lotação encontrada com a matricula ' + this._formStore.matriculaSearch)
                    return
                }

                const [lotacao] = rows

                const resp = await showSwal({
                    title: "Atenção",
                    html: `Deseja adicionar a lotação de <strong>${lotacao.colaborador.nome}</strong> nos lançamentos?`,
                    type: 'info',
                    showCancelButton: true,
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    focusCancel: true,
                })

                if (resp.value) {

                    const lancamento = {
                        lotacao_id: lotacao.id,
                        categoria_id: lotacao.categoria_id,
                        matricula: lotacao.matricula,
                        lotacao_snapshot: lotacao.snapshot,
                        valor_vb: lotacao.vencimento_base,
                        valor_cargo: lotacao.cargo?.remuneracao,
                        colaborador: { ...lotacao.colaborador },
                        categoria: { ...lotacao.categoria },
                        cargo: { ...lotacao.cargo }
                    }

                    if (lancamento.categoria.exibe_campos_agente_comunitario_saude) {
                        lancamento.qtde_faltas_n_justificadas = 0
                        lancamento.qtde_cadastros_realizados = 0
                        lancamento.qtde_visitas = 0
                        lancamento.qtde_visitas_cresca = 0
                        lancamento.qtde_grupos = 0
                        lancamento.qtde_folgas = 0
                    }

                    // situacao da lotacao
                    if (this._formStore.retificado) {
                        const { data: { rows } } = await lancamentoService.getOne({
                            remessa_id: this._formStore.remessaAnteriorId,
                            lotacao_id: lotacao.id
                        })

                        if (rows.length === 0) {
                            lancamento.situacao_lotacao = {
                                updated: false,
                                badgeColor: 'success',
                                badgeText: 'Novo lançamento',
                                snapshot_atual: null,
                                snapshot_anterior: null
                            }
                        } else {
                            const [lanRemessaAnterior] = rows[this._formStore.competencia][this._formStore.origem.label]

                            lancamento.percentual_desconto = lanRemessaAnterior.percentual_desconto
                            lancamento.valor_producao = lanRemessaAnterior.valor_producao

                            if (this.compararSnapshot(lotacao.snapshot, lanRemessaAnterior.lotacao_snapshot)) {
                                lancamento.situacao_lotacao = {
                                    updated: true,
                                    badgeColor: 'warning',
                                    badgeText: 'Lotação atualizada',
                                    snapshot_atual: lotacao.snapshot,
                                    snapshot_anterior: lanRemessaAnterior.lotacao_snapshot,
                                    categoria_atual: lancamento.categoria,
                                    categoria_anterior: lanRemessaAnterior.categoria
                                }
                            }
                        }
                    }

                    this._formStore.addLancamento(lancamento)
                    this._formStore.onChangeMatricula('')
                }
            } catch (error) {
                this._formStore.changeState('error')
                customToast['error'](error.message)
            } finally {
                this._formStore.changeState('done')
            }

        }
    }

    compararSnapshot(snapA, snapB) {

        if (!snapA || !snapB) return false

        snapA = typeof snapA === 'string' ? JSON.parse(snapA) : snapA
        snapB = typeof snapB === 'string' ? JSON.parse(snapB) : snapB

        for (const prop in snapA) {

            if (!snapB.hasOwnProperty(prop)) continue

            if (typeof snapA[prop] === 'string') {
                snapA[prop] = snapA[prop]?.trim()
                snapB[prop] = snapB[prop]?.trim()
            }

            if (snapA[prop] !== snapB[prop])
                return true
        }
        return false
    }

    async onChangeAfastamento(afastamento, lancamento, competenciaRef) {

        const { colaborador: { id, nome } } = lancamento
        let media = null

        if (afastamento) {
            const resp = await showSwal({
                title: 'Atenção',
                html: `Deseja calcular a produção média do colaborador <strong>${nome}<strong/>?`,
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                focusCancel: true
            })

            if (resp.value) {
                try {
                    this._formStore.changeState('pending')
                    const { data } = await lancamentoService.obterProducaoMediaAcs(id, competenciaRef)
                    let valorMedia = 0

                    const competencias = data.reduce((html, row, idx) => {
                        const { valor_producao, competencia } = row
                        return html + `
                            <div class="row">
                                <div class="col-6 text-right ${+valor_producao === 0 ? 'text-danger' : ''}">${idx + 1}) ${competencia}</div>
                                <div class="col-6 text-left ${+valor_producao === 0 ? 'text-danger' : ''}">${valor_producao === null ? 'Não informado' : +valor_producao}</div>
                            </div>`
                    }, '')

                    const existeCompSemInformacao = data.some(r => r.valor_producao === null)

                    if (!existeCompSemInformacao) {
                        const listaProducao = data.map(r => +r.valor_producao)
                        const somaProducao = listaProducao.reduce((total, valor) => total + valor, 0)
                        valorMedia = Math.round(somaProducao / listaProducao.length)
                    }

                    const html = `
                        <div class="container mt-4 mb-4">
                            ${existeCompSemInformacao ?
                            `<div class="row">
                                    <div class="col-12 alert alert-warning" role="alert">
                                        Não foi possível calcular a média, pois há competência(s) sem informação(ões)
                                    </div>
                                </div>`
                            : ''
                        }
                            <div class="row m-2">
                                <div class="col-6 text-right">Competência</div>
                                <div class="col-6 text-left">Valor produzido</div>
                            </div>
                            <hr/>
                            ${competencias}
                            <hr/>
                            <div class="row">
                                ${!existeCompSemInformacao ?
                            `<div class="col-6 text-right">
                                        <strong>MÉDIA</strong>
                                    </div>
                                    <div class="col-6 text-left">
                                        <strong>${valorMedia}</strong>
                                    </div>`
                            : ''
                        }
                            </div>
                        </di>`

                    const swalConfig = {
                        title: `Produção Média de <br>${nome}`,
                        html: html,
                        width: 600,
                        confirmButtonText: 'Fechar',
                        showCancelButton: false,
                        customClass: {
                            confirmButton: 'bg-info',
                        },
                        allowEscapeKey: false,
                        allowOutsideClick: false
                    }

                    if (!existeCompSemInformacao) {
                        swalConfig.confirmButtonText = 'Usar média calculada'
                        swalConfig.cancelButtonText = 'Informar a média manualmente'
                        swalConfig.customClass.cancelButton = 'bg-danger'
                        swalConfig.showCancelButton = true
                    }

                    const resp = await showSwal(swalConfig)

                    if (resp.value && !existeCompSemInformacao) {
                        media = valorMedia
                    }


                } catch (error) {
                    this._formStore.changeState('error')
                    // customToast['error'](error.message)
                } finally {
                    this._formStore.changeState('done')
                }
            }

        }

        this._formStore.lancamentos.forEach(l => {
            if (l.lotacao_id === lancamento.lotacao_id)
                l.onChangeAfastamento(afastamento, media)
        })
    }

    render() {
        const { isEditMode } = this.state

        const gridComponent = (
            <Grid
                showForm={(competencia, origemID, calculado, retificado) => this.showForm(competencia, origemID, calculado, retificado)}
                calculateRemessa={(remessaId) => this.calculateRemessa(remessaId)}
                showReport={(reportName, origemId, competencia) => this.showReport(reportName, origemId, competencia)}
                revertRemessa={(remessaId) => this.revertRemessa(remessaId)}
                onDelete={(id) => this.onDelete(id)}
                onChangePage={(page) => this.onChangePage(page)}

                store={this._gridStore}
            />
        )

        const formComponent = (
            <Form
                onCreate={_ => this.onCreate()}
                onSave={_ => this.onSave()}
                onBack={_ => this.onBack()}
                onChangeOrigem={(value) => this.onChangeOrigem(value)}
                onChangeAfastamento={(afastamento, lancamento, competenciaRef) => this.onChangeAfastamento(afastamento, lancamento, competenciaRef)}
                removerLancamento={(id, nomeColaborador) => this.removerLancamento(id, nomeColaborador)}
                visualizarLotacao={(lotacaoID, atual, anterior) => this.visualizarLotacao(lotacaoID, atual, anterior)}
                addLotacao={_ => this.addLotacao()}

                store={this._formStore} />
        )

        return (
            <Lancamento
                isLoading={this._formStore.state === 'pending' || this._gridStore.state === 'pending'}
                component={isEditMode ? formComponent : gridComponent}
            />
        )
    }
}

export default Container