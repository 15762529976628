import { observer } from "mobx-react"
import * as React from "react"
import Select from "react-select"
import { Badge, Button, Card, Form, Grid, Page, Tab, Table, Tabs } from "tabler-react"
import If from "../../components/if"

// PROVISORIO!!! - HABILITANDO O PRODUCAO MANUAL DAS ORIGENS
const origensHospitais = [
    '3c058071-b225-4b79-825b-920a7e27ef49',
    'c33f9e2a-5671-4d56-89ed-5b2c3ad30e9f',
    'd442c0fb-d667-4227-a5db-be3bde3cbee8',
    '06a51d1c-c8d1-49c9-9b87-b063946e1ead',
    '9027c364-b17b-42f3-99fb-421ec8779438',
    'e499a6c9-26aa-4df8-a4ca-b93e7ae9ab1f',
    'ed4d3e19-08f9-4b6f-b6ca-0c7e1f9393e4',
    '2655afdc-6857-475b-bfc7-9c8933ce88b9',
    '0da8dc0f-3f07-4554-b2c5-003ac4e48456',
    '4b4e8444-67e0-4c3b-9942-59464f7ed144',
    '90193682-04e3-4988-94ef-bb033ebf6b43'
    // '2d72cab8-486f-4fda-b393-4803eae7547e',
    // '149a6851-8458-429a-a1b1-aa9ba540dd5c',
    // 'bdbe01d5-3490-4305-ab3d-4bb0fbb51aed',
    // '4375c9c4-1bcb-4e06-9b2c-09b1d253afd5',
    // '0a15a2bb-b063-4582-b4ca-63efdfe6f1e8',
    // '9904c2cb-1a8f-4ad4-80f7-624cd1024fdb'
]

@observer
class PageForm extends React.Component {
    // PROVISORIO!!!
    habilitaCampoProducao(value) {
        return origensHospitais.some(uuid => uuid === value)
    }

    render() {
        const categorias = this.props.store.lancamentosByCategoria
        const categoriasIds = Object.keys(categorias)

        let habilitaCampoProducao = false

        if (this.props.store.origem) {
            habilitaCampoProducao = this.habilitaCampoProducao(this.props.store.origem.value)
        }

        const tabLotacoes = (
            (this.props.store.lancamentos.length === 0) ? <h4>Nenhum lançamento encontrado.</h4> :
                (
                    <Tabs initialTab={categorias[categoriasIds[0]][0].categoria.descricao}>
                        {categoriasIds.map(categoriaId => {
                            const { categoria } = categorias[categoriaId][0]
                            return (
                                <Tab key={categoriaId} title={categoria.descricao}>
                                    <Table
                                        style={{
                                            display: 'block',
                                            overflowX: 'scroll',
                                            maxHeight: '50vh'
                                        }}
                                        className="table-lancamentos"
                                        responsive
                                        highlightRowOnHover
                                        hasOutline
                                        verticalAlign="center"
                                        cards>
                                        <Table.Header>
                                            <Table.Row>
                                                <If test={!this.props.store.processado && this.props.store.retificado}>
                                                    <Table.ColHeader>Situação</Table.ColHeader>
                                                </If>
                                                <Table.ColHeader>Matrícula</Table.ColHeader>
                                                <Table.ColHeader>CPF</Table.ColHeader>
                                                <th style={{ minWidth: '250px' }}>Nome</th>
                                                <If test={categoria.exibe_campos_agente_comunitario_saude}>
                                                    <th style={{ minWidth: '120px' }}>Afastamento</th>
                                                    <th style={{ minWidth: '120px' }}>Produção média</th>
                                                    <th style={{ minWidth: '120px' }}>Faltas não justificadas</th>
                                                    <th style={{ minWidth: '120px' }}>Cadastros</th>
                                                    <th style={{ minWidth: '120px' }}>Total Visitas</th>
                                                    <th style={{ minWidth: '120px' }}>Visitas (Cresça)</th>
                                                    <th style={{ minWidth: '120px' }}>Grupo</th>
                                                    <th style={{ minWidth: '120px' }}>Folgas</th>
                                                </If>
                                                <If test={!categoria.exibe_campos_agente_comunitario_saude}>
                                                    <If test={categoria.exibe_campo_vencimento_base}>
                                                        <Table.ColHeader>Vencimento base</Table.ColHeader>
                                                    </If>
                                                    <If test={categoria.exibe_campo_cargo}>
                                                        <Table.ColHeader>Valor cargo</Table.ColHeader>
                                                    </If>
                                                    <If test={categoria.exibe_campo_produtividade}>
                                                        <Table.ColHeader>Valor produção</Table.ColHeader>
                                                    </If>
                                                    <Table.ColHeader>Desconto(%)</Table.ColHeader>
                                                </If>
                                                <If test={this.props.store.processado}>
                                                    <th style={{ minWidth: '120px' }}>Compensado</th>
                                                    <th style={{ minWidth: '120px' }}>Valor GIP</th>
                                                </If>
                                                <If test={!this.props.store.processado}>
                                                    <Table.ColHeader>Ação</Table.ColHeader>
                                                </If>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {categorias[categoriaId].map(lancamento => {
                                                return (
                                                    <If test={lancamento.visible} key={`row_${categoriaId}_${lancamento.lotacao_id}`}>
                                                        <Table.Row >
                                                            <If test={!this.props.store.processado && this.props.store.retificado}>
                                                                <Table.Col >
                                                                    <Badge color={lancamento.situacao_lotacao.badgeColor} className="mr-1">
                                                                        {lancamento.situacao_lotacao.badgeText}
                                                                    </Badge>
                                                                </Table.Col>
                                                            </If>
                                                            <Table.Col>{lancamento.matricula}</Table.Col>
                                                            <Table.Col className="text-nowrap">{lancamento.cpf}</Table.Col>
                                                            <Table.Col>{lancamento.colaborador.nome}</Table.Col>
                                                            <If test={categoria.exibe_campos_agente_comunitario_saude}>
                                                                <Table.Col>
                                                                    <Form.Select
                                                                        value={lancamento.afastamento}
                                                                        onChange={e => this.props.onChangeAfastamento(
                                                                            e.target.value,
                                                                            lancamento,
                                                                            this.props.store.competencia
                                                                        )}
                                                                        disabled={lancamento.remessa_id}
                                                                    >
                                                                        <option value="">Selecione</option>
                                                                        <option value="licenca">Licença</option>
                                                                        <option value="ferias">Férias</option>
                                                                    </Form.Select>
                                                                </Table.Col>
                                                                <Table.Col>
                                                                    <Form.Input
                                                                        type='text'
                                                                        value={lancamento.valor_producao_media}
                                                                        onChange={(e) => lancamento.setProducaoMedia(e.target.value)}
                                                                        placeholder='Produção'
                                                                        disabled={lancamento.remessa_id || !lancamento.afastamento}
                                                                    />
                                                                </Table.Col>
                                                                <Table.Col>
                                                                    <Form.Input
                                                                        type='text'
                                                                        value={lancamento.qtde_faltas_n_justificadas}
                                                                        onChange={(e) => lancamento.setQtdeFaltasNJustificadas(e.target.value)}
                                                                        placeholder='Faltas não justificadas'
                                                                        disabled={lancamento.remessa_id || lancamento.afastamento}
                                                                    />
                                                                </Table.Col>
                                                                <Table.Col>
                                                                    <Form.Input
                                                                        type='text'
                                                                        value={lancamento.qtde_cadastros_realizados}
                                                                        onChange={(e) => lancamento.setQtdeCadastrosRealizados(e.target.value)}
                                                                        placeholder='Cadastros'
                                                                        disabled={lancamento.remessa_id}
                                                                    />
                                                                </Table.Col>
                                                                <Table.Col>
                                                                    <Form.Input
                                                                        type='text'
                                                                        value={lancamento.qtde_visitas}
                                                                        onChange={(e) => lancamento.setQtdeVisitas(e.target.value)}
                                                                        placeholder='Total Visitas'
                                                                        disabled={lancamento.remessa_id || lancamento.afastamento}
                                                                    />
                                                                </Table.Col>
                                                                <Table.Col>
                                                                    <Form.Input
                                                                        type='text'
                                                                        value={lancamento.qtde_visitas_cresca}
                                                                        onChange={(e) => lancamento.setQtdeVisitasCresca(e.target.value)}
                                                                        placeholder='Visitas (cresça com seu filho)'
                                                                        disabled={lancamento.remessa_id || lancamento.afastamento}
                                                                    />
                                                                </Table.Col>
                                                                <Table.Col>
                                                                    <Form.Input
                                                                        type='text'
                                                                        value={lancamento.qtde_grupos}
                                                                        onChange={(e) => lancamento.setQtdeGrupos(e.target.value)}
                                                                        placeholder='Grupos'
                                                                        disabled={lancamento.remessa_id || lancamento.afastamento}
                                                                    />
                                                                </Table.Col>
                                                                <Table.Col>
                                                                    <Form.Input
                                                                        type='text'
                                                                        value={lancamento.qtde_folgas}
                                                                        onChange={(e) => lancamento.setQtdeFolgas(e.target.value)}
                                                                        placeholder='Folgas'
                                                                        disabled={lancamento.remessa_id || lancamento.afastamento}
                                                                    />
                                                                </Table.Col>
                                                            </If>
                                                            <If test={!categoria.exibe_campos_agente_comunitario_saude}>
                                                                <If test={categoria.exibe_campo_vencimento_base}>
                                                                    <Table.Col>{lancamento.valorVencimentoBase || '-'}</Table.Col>
                                                                </If>
                                                                <If test={categoria.exibe_campo_cargo}>
                                                                    <Table.Col>{lancamento.valorCargo}</Table.Col>
                                                                </If>
                                                                <If test={categoria.exibe_campo_produtividade}>
                                                                    <Table.Col className="">
                                                                        <Form.Input
                                                                            type='text'
                                                                            value={lancamento.valorProducao}
                                                                            onChange={(e) => lancamento.onChangeValorProducao(e.target.value)}
                                                                            placeholder='Valor produção'
                                                                            disabled={!habilitaCampoProducao || lancamento.remessa_id}
                                                                        />
                                                                    </Table.Col>
                                                                </If>
                                                                <Table.Col className="lancamentos-col-input">
                                                                    <Form.Input
                                                                        type='text'
                                                                        value={lancamento.percentualDesconto}
                                                                        maxLength="6"
                                                                        onChange={(e) => lancamento.onChangePercentualDesconto(e.target.value)}
                                                                        placeholder='Desconto(%)'
                                                                        disabled={lancamento.remessa_id ? true : false}
                                                                    />
                                                                </Table.Col>
                                                            </If>
                                                            <If test={this.props.store.processado}>
                                                                <Table.Col>{lancamento.valorCompensado}</Table.Col>
                                                                <Table.Col>{lancamento.valorGipTotal}</Table.Col>
                                                            </If>
                                                            <If test={!this.props.store.processado}>
                                                                <Table.Col>
                                                                    <If test={lancamento.situacao_lotacao.updated}>
                                                                        <Button
                                                                            outline
                                                                            color="info"
                                                                            size="sm" className="ml-auto mb-2"
                                                                            onClick={() => this.props.visualizarLotacao(
                                                                                lancamento.lotacao_id,
                                                                                lancamento.situacao_lotacao.snapshot_atual,
                                                                                lancamento.situacao_lotacao.snapshot_anterior
                                                                            )}
                                                                        >
                                                                            Visualizar lotações
                                                                        </Button>
                                                                        <br />
                                                                    </If>
                                                                    <Button
                                                                        outline
                                                                        color="danger"
                                                                        size="sm" className="ml-auto"
                                                                        onClick={() => this.props.removerLancamento(
                                                                            lancamento.lotacao_id,
                                                                            lancamento.colaborador.nome
                                                                        )}
                                                                    >
                                                                        Remover
                                                                    </Button>
                                                                </Table.Col>
                                                            </If>
                                                        </Table.Row>
                                                    </If>
                                                )
                                            })}
                                        </Table.Body>
                                    </Table>
                                </Tab>
                            )

                        })}
                    </Tabs>
                )
        )

        const selectOrigem = (
            <Select
                closeMenuOnSelect={true}
                placeholder="Selecione uma origem"
                isClearable={true}
                options={this.props.store.origemSelect}
                onChange={options => this.props.onChangeOrigem(options)}
                value={this.props.store.origem}
                isDisabled={this.props.store.isEdit || this.props.store.competencia.length !== 6}
            >
            </Select>
        )

        return (
            <>
                <Page.Content>
                    <Page.Header>
                        <Page.Title>
                            Lançamentos
                        </Page.Title>
                    </Page.Header>
                </Page.Content>

                <Page.Card
                    footer={
                        <Card.Footer>
                            <div className="d-flex">
                                <Button link onClick={e => this.props.onBack()} type="button">Voltar</Button>
                                <If test={!this.props.store.disableBtnSalvar}>
                                    <Button
                                        color="primary"
                                        className="ml-auto"
                                        type="button"
                                        onClick={e => this.props.onSave()}
                                    >
                                        {this.props.store.isEdit ? 'Atualizar' : 'Salvar'}
                                    </Button>
                                </If>
                            </div>
                        </Card.Footer>
                    }
                >
                    <Grid.Row>
                        <Grid.Col md={3}>
                            <Form.Checkbox
                                label="Lançamentos retificados"
                                checked={this.props.store.retificado}
                                onChange={(e) => this.props.store.onChangeRetificado(e.target.checked)}
                                disabled={this.props.store.isEdit || this.props.store.origem}
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>

                        <Grid.Col md={2}>
                            <Form.Input
                                label='Competência'
                                type='text'
                                value={this.props.store.getCompetencia}
                                onChange={(e) => this.props.store.onChangeCompetencia(e.target.value)}
                                maxLength="7"
                                placeholder='AAAA/MM'
                                disabled={this.props.store.isEdit || this.props.store.origem}

                                feedback={this.props.store.wasValidated ? this.props.store.competenciaError : ''}
                                invalid={this.props.store.wasValidated && this.props.store.competenciaError}
                            />
                        </Grid.Col>

                        <Grid.Col md={10}>
                            <Form.Label>Origem</Form.Label>
                            {selectOrigem}
                        </Grid.Col>

                    </Grid.Row>

                    <h4>Lançamentos</h4>
                    <hr />

                    <If test={this.props.store.lancamentos.length !== 0}>
                        <If test={!this.props.store.processado}>
                            <Grid.Row>
                                <Grid.Col md={2}>
                                    <Form.Input
                                        label="Adicionar lançamento"
                                        type='text'
                                        value={this.props.store.matriculaSearch}
                                        onChange={(e) => this.props.store.onChangeMatricula(e.target.value)}
                                        placeholder='Digite a matrícula'
                                    />
                                </Grid.Col>
                                <Grid.Col md={2}>
                                    <label className="form-label">&nbsp;</label>
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={e => this.props.addLotacao()}>
                                        Adicionar
                                    </Button>
                                </Grid.Col>
                            </Grid.Row>
                        </If>
                        <Grid.Row>
                            <Grid.Col md={9}>
                                <Form.Input
                                    label="Filtrar lançamentos"
                                    type='text'
                                    value={this.props.store.filtroLancamentos}
                                    onChange={(e) => this.props.store.onChangeFiltroLancamento(e.target.value)}
                                    placeholder='Digite a matrícula, CPF ou o nome para pesquisar'
                                />
                            </Grid.Col>
                        </Grid.Row>
                    </If>

                    <Grid.Row>
                        <Grid.Col md={12} className="text-center">
                            {tabLotacoes}
                        </Grid.Col>
                    </Grid.Row>

                </Page.Card>
            </>
        )
    }
}

export default PageForm